import {
  StatusCode,
  statusTextFromCode,
} from '../types/StatusCode/StatusCode.types';

export class UnknownError extends Error {
  /* eslint-disable-line @typescript-eslint/ban-ts-comment */ /* @ts-ignore */
  constructor(private error: unknown) {
    super();

    this.name = 'Unknown Error';

    if (error instanceof Error) {
      this.message = error.message;
    } else if (typeof error === 'string') {
      this.message = error;
    } else {
      this.message = 'An unknown error occurred.';
    }
  }

  statusCode = (): StatusCode => {
    return StatusCode.InternalServerError;
  };

  statusText = (): string => {
    return statusTextFromCode(this.statusCode());
  };
}

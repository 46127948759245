import {
  StatusCode,
  statusTextFromCode,
} from '../types/StatusCode/StatusCode.types';
import { ValidationError } from './ValidationError';
import { UnknownError } from './UnknownError';
import { RequestError, axiosErrorThrown } from './RequestError';

export class GeneralError extends Error {
  constructor(
    message: string,
    private _statusCode?: StatusCode,
  ) {
    super(message);

    this.name = 'General Error';

    this._statusCode = _statusCode;
  }

  statusCode = (): StatusCode => {
    if (this._statusCode) {
      return this._statusCode;
    }

    return StatusCode.InternalServerError;
  };

  statusText = (): string => {
    return statusTextFromCode(this.statusCode());
  };
}

function isErrorType<T>(value: unknown, name: string): value is T {
  if (!(value instanceof Error)) return false;
  if (value.name !== name) return false;

  return true;
}

export function isGeneralError(value: unknown): value is GeneralError {
  return isErrorType(value, 'General Error');
}

export function isValidationError(value: unknown): value is ValidationError {
  return isErrorType(value, 'Validation Error');
}

export function isRequestError(value: unknown): value is RequestError {
  return isErrorType(value, 'Request Error');
}

export function isUnknownError(value: unknown): value is UnknownError {
  return isErrorType(value, 'Unknown Error');
}

export const logError = (
  err: GeneralError | ValidationError | RequestError | UnknownError,
): void => {
  console.error('----Begin Error----');

  console.error(err.name);

  console.error(`Timestamp: ${new Date().toString()}`);

  if (isValidationError(err)) {
    console.error(`Value: `, err.value);
    console.error(`Reason: ${err.errors}`);

    if (err.location) {
      console.error(`Location: ${err.location}`);
    }
  }

  if (isRequestError(err)) {
    console.error(`Status Code: ${err.statusCode()}`);
    console.error(`Status Text: ${err.statusText()}`);

    if (err.config.url) {
      console.error(`Origin URL: ${err.config.url}`);
    }
    if (err.config.baseURL) {
      console.error(`Origin Base URL: ${err.config.baseURL}`);
    }
    if (err.config.method) {
      console.error(`Method: ${err.config.method}`);
    }
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  console.error(`Stacktrace: ${err.stack}`);

  console.error('----End Error----');
};

export const logErrorCollection = (errs: unknown[]): void => {
  errs.forEach((e, i) => {
    let _e: GeneralError | ValidationError | RequestError | UnknownError;

    if (
      isGeneralError(e) ||
      isValidationError(e) ||
      isRequestError(e) ||
      isUnknownError(e)
    ) {
      _e = e;
    } else if (axiosErrorThrown(e)) {
      _e = new RequestError(e);
    } else {
      _e = new UnknownError(e);
    }
    console.error(`Logging error ${i + 1} of ${errs.length}`);
    logError(_e);
  });
};

import {
  ForecastResponse,
  Policy,
  WeatherVariable,
} from '../utils/types/Entity';
import axios, { AxiosResponse } from 'axios';
import { auth, err } from '../utils';

const URL = '/api/forecast';

const getPolicyForecast = async (
  token: string,
  policy: Policy,
): Promise<ForecastResponse | undefined> => {
  try {
    const temperatureVariable =
      policy.exposures[0].perils[0].parameterUnit === 'celsius'
        ? WeatherVariable.TEMPERATURE_C
        : WeatherVariable.TEMPERATURE_F;
    const res: AxiosResponse<ForecastResponse> = await axios.get(URL, {
      headers: auth.bearerAuthHeader(token),
      params: {
        weatherVariables: [
          WeatherVariable.RAIN,
          temperatureVariable,
          WeatherVariable.CLOUD,
        ],
        latitude: policy.exposures[0].attributes.latitude,
        longitude: policy.exposures[0].attributes.longitude,
        start: policy.start,
        end: policy.end,
      },
      paramsSerializer: {
        indexes: null,
      },
    });
    if (res.status < 200 || res.status >= 300) {
      throw new Error(`${res.status} error`);
    }

    return res.data;
  } catch (e) {
    throw err.handleError(e, 'error getting forecast data');
  }
};

export default {
  getPolicyForecast,
};

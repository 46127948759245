import { useTranslation } from 'react-i18next';
import {
  PerilConfigurations,
  LossUnitDisplayMap,
  ParameterUnit,
} from '../../utils/types/Entity';
import Typography from '../../sharedComponents/Typography/Typography';
import { PerilCoverageDetailsProps } from './PerilCoverageDetails.types';

const PerilCoverageDetails: React.FC<PerilCoverageDetailsProps> = ({
  perilCoverage,
}) => {
  const { t } = useTranslation();

  const generateContent = (): string => {
    let content = '';
    // TODO: #2259 remodel the translationMap - description in the issue
    perilCoverage.forEach((c, i) => {
      const perilConfig = PerilConfigurations[c.type];
      if (['celsius', 'fahrenheit'].includes(c.parameterUnit)) {
        perilConfig.unit = c.parameterUnit as ParameterUnit;
      }
      const displayLossUnit = LossUnitDisplayMap[perilConfig.lossUnit];
      const text = t(`label.${c.type}_greater_than`, {
        threshold: c.parameterThresholdUpper,
        lossUnit: t(`label.abbr.${displayLossUnit}`),
        formatParams: {
          threshold: {
            style: 'unit',
            unit: perilConfig.unit,
          },
        },
      });
      if (i === 0) {
        content = text;
      } else if (i === perilCoverage.length - 1) {
        content = `${content} ${t('label.or')} ${text}`;
      } else {
        content = `${content}, ${text}`;
      }
    });

    return content;
  };

  return <Typography variant="body-1">{generateContent()}</Typography>;
};

export default PerilCoverageDetails;

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { StatusCode } from '../types/StatusCode/StatusCode.types';
import { UnknownError } from './UnknownError';

type axiosError = {
  config: {
    url?: string;
    baseURL?: string;
    method?: string;
  };
  code?: string;
  response: {
    data: {
      message: string;
      code: number;
    };
    status: number;
    statusText: string;
  };
  isAxiosError: boolean;
};

export class RequestError extends Error {
  config: { url?: string; baseURL?: string; method?: string };
  private _statusCode: StatusCode;
  private _statusText: string;

  /* eslint-disable-line @typescript-eslint/ban-ts-comment */ /* @ts-ignore */
  constructor(private error: axiosError) {
    if (!error.isAxiosError) {
      throw new UnknownError(error);
    }

    const message = `${error.config.method} ${
      error.config.url || error.config.baseURL
    } failed`;
    super(message);

    this.name = 'Request Error';

    this._statusCode = error.response.status;
    this._statusText = error.response.statusText;
    this.config = error.config;
  }

  statusCode = (): StatusCode => {
    return this._statusCode;
  };

  statusText = (): string => {
    return this._statusText;
  };
}

export function axiosErrorThrown(err: unknown): err is axiosError {
  try {
    if (err instanceof Array || typeof err !== 'object') {
      return false;
    }

    return (err as Record<string, unknown>).isAxiosError === true;
  } catch (e) {
    /* istanbul ignore next */
    return false;
  }
}

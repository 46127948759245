import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Typography from '../../sharedComponents/Typography/Typography';
import { useStore } from '../../store/hooks';
import { PageProps } from '../Page.types';
import TripListContainer from '../../containers/TripListContainer/TripListContainer';
import styles from './trips.module.scss';
import Footer from '../../components/Footer/Footer';

const Trips: React.FunctionComponent<PageProps> = ({
  layout: Layout,
  navigation: Navigation,
}) => {
  const {
    policyBundles: { policyBundles, ready: policyBundlesReady },
    user: { user },
  } = useStore();

  const { t } = useTranslation();

  return (
    <Layout navigation={<Navigation />} footer={<Footer />}>
      <div className={styles.greeting}>
        {user && (
          <Typography variant="h4">
            {t('label.user_greeting', {
              name: user.name.split(' ')[0],
            })}
          </Typography>
        )}
        <Typography variant="body-1">
          {t('sunshine.content.trips.header')}
        </Typography>
      </div>
      <div>
        {!policyBundlesReady ? (
          <Typography variant="h5">{t('label.loading')}</Typography>
        ) : (
          <TripListContainer policyBundles={policyBundles} />
        )}
      </div>
      <div className={styles.footer}>
        <Typography variant="h5">
          {t('sunshine.content.questions.header')}
        </Typography>
        <Typography variant="body-1">
          <Trans
            i18nKey="sunshine.content.questions.explainer_with_link"
            values={{ faqs: t('generic.label.faqs') }}
          >
            <a
              href={t('generic.faqs.link')}
              aria-label={t('generic.label.faqs')}
            />
            <a
              href={t('generic.contact_support.link')}
              aria-label={t('generic.label.customer_support_email')}
            />
          </Trans>
        </Typography>
      </div>
    </Layout>
  );
};

export default Trips;

//used in client
import { isLoss, isLossUnit, Loss, LossUnit } from './Loss.types';
import {
  arrayValidator,
  numberValidator,
  objectValidator,
  stringValidator,
} from '../../validate';
import { convertMetersToMillimeters } from '../calculations/length';
import { ValidationError } from '../../error';

export interface Peril {
  name: string;
  type: PerilType;
  parameterUnit: ParameterUnit;
  parameterThresholdUpper: number;
  lossUnit: LossUnit;
  losses: Loss[];
}

export enum PerilType {
  Rainfall = 'rainfall',
  Wind = 'wind',
  Precipitation = 'precipitation',
  Temperature = 'temperature',
  HeatIndex = 'heat_index',
}

export enum ParameterUnit {
  Meter = 'meter',
  Millimeter = 'millimeter',
  Fahrenheit = 'fahrenheit',
  Celsius = 'celsius',
}
export const ParameterUnitDisplayMap: Record<ParameterUnit, string> = {
  [ParameterUnit.Millimeter]: 'mm',
  [ParameterUnit.Meter]: 'm',
  [ParameterUnit.Fahrenheit]: '°F',
  [ParameterUnit.Celsius]: '°C',
};

export interface PerilConfiguration {
  displayType: string;
  valueConverter: (value: number) => number;
  unit: ParameterUnit;
  lossUnit: LossUnit;
}

export const PerilConfigurations: {
  [perilType in PerilType]: PerilConfiguration;
} = {
  [PerilType.Rainfall]: {
    displayType: 'rain',
    valueConverter: convertMetersToMillimeters,
    unit: ParameterUnit.Millimeter,
    lossUnit: LossUnit.Hour,
  },
  [PerilType.Wind]: {
    displayType: 'wind',
    valueConverter: (value: number) => value,
    unit: ParameterUnit.Meter,
    lossUnit: LossUnit.Second,
  },
  [PerilType.Precipitation]: {
    displayType: 'precipitation',
    valueConverter: convertMetersToMillimeters,
    unit: ParameterUnit.Millimeter,
    lossUnit: LossUnit.Hour,
  },
  [PerilType.Temperature]: {
    displayType: 'temperature',
    valueConverter: (value: number) => value,
    unit: ParameterUnit.Fahrenheit,
    lossUnit: LossUnit.Hour,
  },
  [PerilType.HeatIndex]: {
    displayType: 'temperature',
    valueConverter: (value: number) => value,
    unit: ParameterUnit.Fahrenheit,
    lossUnit: LossUnit.Hour,
  },
};

function isPerilType(value: unknown): asserts value is PerilType {
  switch (value) {
    case PerilType.Rainfall:
    case PerilType.Wind:
    case PerilType.Precipitation:
    case PerilType.Temperature:
    case PerilType.HeatIndex:
      return;
    default:
      throw new ValidationError(value, 'perilType', [
        `error validating PerilType: received ${JSON.stringify(value)}`,
      ]);
  }
}

/* Protect's peril types */
const RAINFALL_M_SURFACE = 'rainfall_m_surface';
const WINDSPEED_MPS_10M = 'windspeed_mps_10m';
const PRECIPITATION_M_SURFACE = 'precipitation_m_surface';
const TEMPERATURE_F = 'temperature_f_2m';
const HEAT_INDEX_F_2M = 'heatindex_f_2m';

export const stringToPerilType = (str: string): PerilType | null => {
  if (typeof str !== 'string') return null;
  switch (str.toLowerCase()) {
    case RAINFALL_M_SURFACE:
      return PerilType.Rainfall;
    case WINDSPEED_MPS_10M:
      return PerilType.Wind;
    case PRECIPITATION_M_SURFACE:
      return PerilType.Precipitation;
    case HEAT_INDEX_F_2M:
      return PerilType.HeatIndex;
    case TEMPERATURE_F:
      return PerilType.Temperature;
    default:
      return null;
  }
};

function isParameterUnit(value: unknown): asserts value is ParameterUnit {
  switch (value) {
    case ParameterUnit.Meter:
    case ParameterUnit.Millimeter:
    case ParameterUnit.Fahrenheit:
    case ParameterUnit.Celsius:
      return;
    default:
      throw new ValidationError(value, 'peril.parameterUnit', [
        `error validating ParameterUnit: received ${JSON.stringify(value)}`,
      ]);
  }
}

export const stringToParameterUnit = (str: string): ParameterUnit | null => {
  if (typeof str !== 'string') return null;

  switch (str.toLowerCase()) {
    case 'meter':
      return ParameterUnit.Meter;
    case 'millimeter':
      return ParameterUnit.Millimeter;
    case 'fahrenheit':
      return ParameterUnit.Fahrenheit;
    default:
      return null;
  }
};

export function isPeril(value: unknown): asserts value is Peril {
  objectValidator(value, 'peril');

  const {
    name,
    type,
    parameterUnit,
    parameterThresholdUpper,
    lossUnit,
    losses,
  } = value;

  const requiredStrings = [{ name: 'peril.name', value: name }];

  requiredStrings.forEach((str) => {
    stringValidator(str.value, str.name, { nonEmpty: true });
  });

  const requiredNumbers = [
    { name: 'peril.parameterThresholdUpper', value: parameterThresholdUpper },
  ];

  requiredNumbers.forEach((num) => {
    numberValidator(num.value, num.name);
  });

  isPerilType(type);
  isParameterUnit(parameterUnit);
  isLossUnit(lossUnit);

  const requiredArrays = [
    { name: 'peril.losses', value: losses, typeguard: isLoss },
  ];

  requiredArrays.forEach((arr) => {
    arrayValidator(arr.value, arr.name, { typeguard: arr.typeguard });
  });
}
